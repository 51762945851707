import React from 'react';
import './ProductContent.scss';

export default function ProductContent(props) {
    const { item } = props

    return (
        <React.Fragment>
            <div className="product-details-content">

                <h3 className="text-black mb-2" style={{ fontWeight: 'bold' }}>
                    {item.title}
                </h3>

                <h2 className='text-black mt-4 mb-4'>
                    $ {item.price}
                </h2>

                <div className='mt-4 mb-4'>
                    {
                        (item?.attributes || []).map((attribute, index) => (
                            attribute.valueName ?
                                <div key={index} className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                        <p className="text-black" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                            {attribute.name}
                                        </p>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                        <p className="text-black" style={{ fontSize: '14px' }}>
                                            {attribute.valueName}
                                        </p>
                                    </div>
                                </div> : null
                        ))
                    }
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12" style={{ marginBottom: '1rem' }}>
                        <div dangerouslySetInnerHTML={{ __html: item.description }} className="description-lots" style={{
                            fontSize: '16px'
                        }} />
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}
