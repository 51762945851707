import React, { useEffect, useState } from 'react';
import ProductImage from '../../components/product-details/ProductImage';
import ProductContent from '../../components/product-details/ProductContent';
import Loading from '../../components/loading/Loading';
import './styles.scss';
import { Constants } from '../../Constants';
import Axios from 'axios';

export default function Home(props) {
    const [id] = useState(props.match.params.id);
    const [item, setItem] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        Axios.get(`${Constants.BASE_URL}/items/${id}`)
            .then((response) => {
                setItem(response.data.data.item);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className='p-4 mt-5 product-box'>
                <div className='container'>
                    <section className={'products-details-area row'}>
                        <div className="col-12 col-md-5 order-md-1 order-2">
                            <ProductImage images={item?.pictures || []} />
                            <span className='d-md-none'>
                                <ProductContent item={item} />
                            </span>
                        </div>
                        <div className='col-12 col-md-5 order-md-2 order-1'>
                            <span className='d-none d-md-block'>
                                <ProductContent item={item} />
                            </span>
                        </div>
                    </section>
                </div>
            </div>
        </React.Fragment >
    );
}
