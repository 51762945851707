import React from 'react';
import { Link } from 'react-router-dom';
import GoTop from '../../Shared/GoTop';
import './styles.scss';

export default function Footer() {
    return (
        <footer className="footer-background">
            <div className='container'>
                <div className="row footer-information">
                    <a href={`https://wa.me/59892888222`}
                        className="whatsapp" rel='noopener noreferrer' target="_blank"> <i className="fab fa-whatsapp"></i>
                    </a>
                    <div className={`d-none d-md-block col-md-4`}>
                        <Link to="/">
                            <img alt='logo' style={{ maxWidth: '100%', marginBottom: '1rem' }}
                                src="https://api.reysubastas.com/public/images/account/2024/09/05/cHJvcGVydGllcw==/Logo%20Vectorizado%20(1)%201.png_1725559907.png"></img>
                        </Link>
                    </div>
                    <div className={`d-none d-md-block col-md-1`} />
                    <div className={`col-6 col-md-4 footer-section`}>
                        <h6 className="font-weight-bold mb-4">Contacto</h6>
                        <p>Maldonado 1083</p>
                        <p>2901 2766 - 092 888 222</p>
                        <p>contacto@reysubastas.com</p>
                    </div>
                    <div className="col-6 col-md-3 footer-section">
                        <h6 className=" font-weight-bold mb-4">Seguridad</h6>
                        <Link to="/inicio"><p>Inicio</p></Link>
                        <Link to="/nosotros"><p>Nosotros</p></Link>
                        <Link to='/contacto'><p>Contacto</p></Link>
                    </div>
                </div>
            </div>
            <div className='footer-bottom' style={{ borderTop: '1px solid #e8e8e8' }}>
                <div className='container'>
                    <div className='row' style={{ padding: '0.5rem 0rem' }}>
                        <div className="col-12 text-center">
                            <span style={{ fontSize: '12px' }}>
                                @ Copyright {new Date().getFullYear()}.&nbsp; All right reserved&nbsp;
                                <u>
                                    <a target="_blank" rel="noopener noreferrer" className="text-black link"
                                        href="https://reyantiguedades.com"> Rey Antiguedades
                                    </a>
                                </u>
                            </span>
                        </div>
                        <div className="col-12 text-center">
                            <span style={{ fontSize: '12px' }}>
                                Powered by &nbsp;
                                <u>
                                    <a target="_blank" rel="noopener noreferrer" className="text-black link" href="https://sayasoftware.com">SayaSoftware</a>
                                </u>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <GoTop scrollStepInPx="250" />
        </footer >
    );
};
