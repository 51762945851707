import React, { Component } from 'react';
import { getBanners } from '../../services/BannersServices';
import CarouselPhotos from '../carousel/CarouselPhotos';

class BannerSlider extends Component {
  state = {
    banners: [],
  };

  componentDidMount() {
    // this.fetchBanners();
    this.setState({
      banners: [
        {
          "id": 5,
          "id_remate": null,
          "image": "https://api.reysubastas.com/public/images/banners/2024/03/03/cHJvcGVydGllcw==/blob_1709502833.",
          "is_active": 1,
          "is_principal": 1,
          "text_principal": "\u00a1Hoy te toca ganar a vos!",
          "position": 1,
          "updated_at": "2024-03-16T13:32:00.000000Z",
          "created_at": "2024-03-03T21:53:55.000000Z",
          "auction_name": null
        }
      ],
    });
  }

  fetchBanners = async () => {
    try {
      let response = await getBanners();
      await this.setState({
        banners: response.data.data.banners,
      });
    } catch (error) { }
  };

  render() {
    return (
      <React.Fragment>
        <CarouselPhotos photos={this.state.banners} />
      </React.Fragment>
    );
  }
}

export default BannerSlider;
