import React, { useEffect, useState } from 'react';
import './styles.scss';
import BannerSlider from '../../components/home/BannerSlider';
import { ContactUs } from '../../components/home/ContactUs';
import Loading from '../../components/loading/Loading';
import Axios from 'axios';
import { Constants } from '../../Constants';
import { Carousel } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';

export default function Home(props) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totals, setTotals] = useState(1);
  const [offset, setOffset] = useState(12);

  useEffect(() => {
    setLoading(true);
    Axios.get(`${Constants.BASE_URL}/items/get-items?page=${page}&offset=${offset}`)
      .then((response) => {
        setItems(response.data.data.items);
        setTotals(response.data.data.totals);
        if (page > 1) {
          window.scrollTo(0, document.getElementById('items').offsetTop - 40);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  useEffect(() => {
    document.title = 'Rey Antiguedades'
  }, [])

  return (
    <div className='home-box'>
      <BannerSlider />
      {loading && <Loading />}

      <section className='light' id="items">
        <div className='container'>
          <div className='row'>
            {items.map((item) => (
              <div key={item.id} className='col-md-3 col-sm-4 col-xs-12'>
                <div className='item'>
                  <Carousel variant="dark" interval={null}>
                    {item.pictures.map(image =>
                      <Carousel.Item>
                        <img
                          onClick={() => props.history.push(`/items/${item.id}`)}
                          style={{ cursor: 'pointer' }}
                          className="d-block w-100"
                          src={`${image.url}`}
                          alt="sin imagen"
                        />
                      </Carousel.Item>
                    )}
                  </Carousel>
                  <div className='item-details'>
                    <Link to={`/items/${item.id}`}>
                      <p>{item.title}</p>
                      <h4>{item.currencyId === 'UYU' ? '$' : 'US$'} {item.price}</h4>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
            <div className='container-pagination'>
              <Pagination
                activePage={page}
                itemsCountPerPage={offset}
                totalItemsCount={totals}
                pageRangeDisplayed={
                  window.innerWidth < 768 ? 3 : 5
                } // si es mobile poner 3
                onChange={(value) => setPage(value)}
              />
            </div>
          </div>
        </div>
      </section>

      <ContactUs />
    </div >
  );
};