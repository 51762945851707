import React from 'react';
import PrimaryMenu from './primary-menu';
import SecondaryMenu from './secondary-menu';
import './styles.scss';

export default function Menu() {

  return <div className='menu'>
    <SecondaryMenu />
    <PrimaryMenu />
  </div>;
};
