import React from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../../Constants';
import { useAccount } from '../../../store/account';


export default function SecondaryMenu() {
  const accountStore = useAccount();

  return (
    <div className='secondary-menu secondary-background d-flex flex-row justify-content-center align-items-center'>
      <div className='p-5 container position-relative'>
        <div className='d-flex justify-content-center'>
          <Link to="/"><img alt='logo' src="https://api.reysubastas.com/public/images/account/2024/09/05/cHJvcGVydGllcw==/Logo%20Vectorizado%201.png_1725559364.png"
            style={{ width: '250px' }}></img></Link>
        </div>
      </div>
    </div>
  );
};