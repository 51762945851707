/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

import './CarouselPhotos.scss';
import { Constants } from '../../Constants';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class CarouselPhotos extends Component {

  scrollToAuctionsList = () => {
    document.getElementById('auctions-list').scrollIntoView();
  }

  render() {
    return (
      <Carousel indicators={false} className='carousel-home'>
        {this.props.photos.map((photo) => (
          <Carousel.Item interval={5000}>
            <img
              className='d-block w-100'
              src={`${photo.image}`}
            />
            <div className='details-for-banner'>
              <div>
                <img alt='logo' src="https://api.reysubastas.com/public/images/account/2024/09/05/cHJvcGVydGllcw==/Logo%20Vectorizado%20(1)%201.png_1725559907.png" />
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
  accountStore: state.accountReducer,
});

export default connect(mapStateToProps, null)(CarouselPhotos);